import { Box, Center } from "@chakra-ui/react";
import Image from "next/image";

const Thumbnail = (props: {
  ext_file: string;
  url: string;
  thumbnail: string;
  comp_file?: string;
}) => {
  return (
    <>
      <Center>
        <Box
          borderRadius="md"
          maxWidth={350}
          minWidth={260}
          minHeight={260}
          overflow="hidden"
        >
          <Image
            src={props.comp_file ?? props.ext_file ?? props.url}
            alt="Thumbnail"
            height={380}
            width={380}
          />
        </Box>
      </Center>
    </>
  );
};

export default Thumbnail;
